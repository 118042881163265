import React from 'react';
import { Link } from 'gatsby';
import Layout from '../App/components/layout';

import * as ROUTES from '../constants/routes';


const EmailChanged = () => (
    <Layout>
        <div className="wrapper">
            <h1>We have updated your email address.</h1>
                
            <p>We just need you to confirm your new address. Once you've confirmed you will be able to <Link to={ROUTES.SIGN_IN} >sign in.</Link> </p>

                <div>
                <p><strong>Still having issues?</strong></p>
                <ol>
                    <li>Are you checking the right email?</li>
                    <li>You may need to check your email spam folder.</li>
                    <li>Your email may be delayed.</li>
                </ol>
                <p>If you are still experiencing issues, please <a href="mailto:info@ceca-mcp.com" target="_blank" rel="noopener noreferrer">Contact Support.</a></p>
            </div>
        </div>
    </Layout>
)
export default EmailChanged;